@font-face {
    font-family: "Work";
    src: url("./fonts/WorkSans-VariableFont_wght.ttf");
  }

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 900px,
  xl: 920px,
  xxl: 960px
);


$primary: #A7C957;
$dark: #6A994E;
$darker: #386641;
$secondary: #BC4749;
$basic: #F2E8CF;
$gray: #787878;
$btn-border-radius: 0.2rem;

$input-placeholder-color: #a1a1a1;


@import '~bootstrap/scss/bootstrap.scss';

/*
 * 공통
 */
body {
    font-family: 'Work' !important;
}
.fixed-top {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding: 13px 0;
}
.container-lg {
    padding-top: 47px;
    padding-bottom: 50px;
}
.fixed-bottom-nav {
    z-index: 99;
    position: fixed;
    bottom:0;
    max-width: 960px;
    width: 100%;
    background-color: rgba(256, 256, 256, 1);
    --tw-shadow: 1px -5px 15px 1px hsla(0,0%,75%,.1);
    --tw-shadow-colored: 1px -5px 15px 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    border-radius: 18px 18px 0 0;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-around;
    color: $gray;
    padding: 0.3rem 1rem 0.3rem 1rem;
}
.loading-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/*
 * 유틸성
 */
.cursor-pointer {
    cursor: pointer;
}
.text-14 {
    font-size: 14px;
}
.text-gray {
    color: $gray !important;
}
.text-darker {
    color:$darker;
}
.logo-image {
    width: 124px;
    height: 24px;
}
.tag-primary {
    font-size: 14px !important;
    font-weight: unset !important;
    width: fit-content !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: #fff !important;
    background-color: $primary;
}
.tag-light {
    font-size: 14px !important;
    font-weight: unset !important;
    width: fit-content !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: $gray !important;
    background-color: #eee;
    border: 1px solid #ccc;
}
.bg-basic {
    background-color: $basic;
}
.icon {
    width: 15px;
    height: 15px;
}
.border-radius-8 {
    border-radius: 8px;
}

/* 
 * Playground
 */
.thumbnail {
    max-height: 300px;
    overflow: hidden;
    object-fit:cover
}
.facility-text {
    font-size: 14px;
    // color:$darker;
}
.carousel-inner {
    border-radius: 0.375rem 0.375rem 0 0;
    background-color: #000;
}
.carousel-item img {
    object-fit: contain;
    border-radius: 0.375rem 0.375rem 0 0;
}
.carousel-item{
    transition: transform 0.3s ease-in-out !important;
}
.no-playground {
    padding: 2rem;
    text-align: center;
    display: none;
}
.playground-container div.no-playground:only-child {
    display: block;
}
.search-info-container{
    margin: 0 1rem 1rem 1rem;
    border: 0;
    background-color: $basic;
    .icon {
        width: 17px;
        height: 17px;
    }
}
span.label-left {
    position: absolute;
    top: 20px;
    left: 20px;
    text-transform: uppercase;
    color: #ffffff;
    background: #fb6107;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 0 4px;
}
span.label-right {
    position: absolute;
    top: 20px;
    right: 20px;
    text-transform: uppercase;
    color: #ffffff;
    background: #f21b3f;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    display: block;
    border-radius: 5px;
    padding: 0 4px;
}
span.label-right.bg-yellow {
    background: #fdc500 !important;
}
/*
 * Contribute
 */
.contribute-section {
    min-height: 290px;
    width: 100%;
    height: auto;
    background-image: url('../public/images/contribute.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
}
.contribute-content {
    padding: 1rem;
    margin-left: 50%;
    font-size: 14px;
    color: $gray;
    .title {
        color: #000;
        font-size:18px;
    }
}
.contribute-button {
    border-radius: 10px;
    padding: 0.25rem 0.725rem 0.25rem 0.725rem;
    margin-top: 1rem;
    margin-right: 1rem;
    font-size: 14px;
}

/*
 * Playground Detail
 */
.playground-detail-container .card {
    margin-top: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border : 0;
}

.playground-detail-container .carousel-inner {
    border-radius: 0 !important;
}
.playground-detail-container .map {
    width: calc(100% - 2rem);
    height: 30vh;
    margin: 0 1rem;
    border-radius: 8px;
}

.playground-detail-container .map .near-me {
    display: none;
}

/*
 * Map
 */ 
.map {
    width: 100%;
    height: calc(100vh - 70px);
}

/*
 * Search
 */
.keyword-input {
    border-radius: 28px 0 0 28px !important;
    border-right: 0 !important;
    padding: 1rem 2rem 1rem 2rem !important;
}
.keyword-input:focus {
    box-shadow: unset !important;
    border-color: var(--bs-border-color) !important;
}
.keyword-icon {
    background-color: #fff !important;
    border-radius: 0 28px 28px 0 !important;
    border-left: 0 !important;
    padding-right: 1.5rem !important;
}
.search-checkbox {
    width: 1.25rem;
    height: 1.25rem;
}
.border-checked  {
    border-color: $primary;
}
.facility-check .icon {
    width: 19px;
    height: 19px;
}
.search-btn {
    border-radius: 20px;
    color: #fff;
}
.search-tag-primary {
    font-size: 15px !important;
    font-weight: unset !important;
    width: fit-content !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: #fff !important;
    background-color: $primary;
    padding: 1rem;
}
/*
 * Terms
 */
.terms p, .terms li {
    font-size: 0.7rem;
} 
.back-arrow {
    position: absolute;
    top: 55px;
    left: 15px;
}